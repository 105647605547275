import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import Main from "../../layout/Main";
import PrintPre from "../../layout/print-pre";
import Title from "../../layout/Title";
import Loader2 from "../utilities/Loader2";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
// import {  } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import authService from "../../services/auth.service";
import AddFaq from "./modals/add-faq";
import { NeoGenHelpDocs } from "../../typings/api";
import { Breadcrumb, ModalBody } from "react-bootstrap";
// import "./faq.css";
import DeleteFaq from "./modals/delete-faq";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageDescription from "../../layout/page-description";
import RecordingButton from "../../layout/recording-button";
import ButtonNeoGen from "../../layout/button-neogen";
import TableNeogen from "../../layout/table-neogen";
// import { faCircleO } from "@fortawesome/pro-light-svg-icons";

export default function Faq() {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [canAddFaqs, setCanAddFaqs] = useState(false);
    const [showAddFaq, setShowAddFaq] = useState(false);
    const [searched, setSearched] = useState(false);
    const [searching, setSearching] = useState(false);
    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showAll, setShowAll] = useState(true);
    const [searchResults, setSearchResults] = useState<NeoGenHelpDocs[]>([]);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const location: { state: unknown } = useLocation();
    const { term } = useParams();
    const navigate = useNavigate();
    // console.log(location);
    const helpDocsQuery = useQuery(["HelpDocs"], async () => {
        const response = await helpDocsService.getAll();
        console.log(response);
        // alert("!")
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        authService.canIAccess("ADD_FAQ").then((r) => {
            setCanAddFaqs(r);
        });
    }, []);
    const convertContentToHTML = () => {
        const currentContentAsHTML = convertToHTML({
            styleToHTML: (style: any) => {
                console.log(style);
                if (style === "BOLD") {
                    return <span style={{ fontWeight: "bold" }} />;
                }
                if (style.substring(0, 9) === "fontsize-") {
                    return <span style={{ fontSize: style.substring(9) + "px" }} />;
                }
            },
            blockToHTML: (block: any) => {
                console.log(block);
                if (block.type === "PARAGRAPH") {
                    return <p />;
                } else if (block.type === "unstyled") {
                    return <div style={{ minHeight: "2rem" }} />;
                }
            },
            entityToHTML: (entity: any, originalText: any) => {
                console.log(entity, originalText);
                if (entity.type === "LINK") {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(editorState.getCurrentContent(), { flat: true });
        // console.log(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
    };

    // const createMarkup = (html: any) => {
    //     console.log(DOMPurify.sanitize(html));
    //     return {
    //         __html: DOMPurify.sanitize(html)
    //     };
    // };
    // const onEditorStateChange = (editorState: any) => {
    //     console.log(editorState);
    //     setEditorState(editorState);
    //     convertContentToHTML();
    // };

    useEffect(() => {
        if (term) {
            search(term);
        }
    }, [term]);

    function getMarkupFromRaw(h: NeoGenHelpDocs) {
        const currentContentAsHTML = convertToHTML({
            styleToHTML: (style: any) => {
                console.log(style);
                if (style === "BOLD") {
                    return <span style={{ fontWeight: "bold" }} />;
                }
                if (style.substring(0, 9) === "fontsize-") {
                    return <span style={{ fontSize: style.substring(9) + "px" }} />;
                }
            },
            blockToHTML: (block: any) => {
                console.log(block);
                if (block.type === "PARAGRAPH") {
                    return <p />;
                } else if (block.type === "unstyled") {
                    return <div style={{ minHeight: "2rem" }} />;
                }
            },
            entityToHTML: (entity: any, originalText: any) => {
                console.log(entity, originalText);
                if (entity.type === "LINK") {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(convertFromRaw(JSON.parse(h.body)));
        console.info(currentContentAsHTML);
        console.info(convertFromRaw(JSON.parse(h.body)));
        return currentContentAsHTML;
    }

    function search(subject: string) {
        setSearching(true);
        helpDocsService.search(subject).then((r) => {
            console.info(r);
            if (r) {
                setSearching(false);
                setSearched(true);
                setSearchResults(r.data);
            }
        });
    }
    useEffect(() => {
        if ((location?.state as string)?.toString()?.length > 0) {
            // console.log((location?.state as string).toString());
            search(location?.state as string);
            // location.state = "";
        }
    }, [location, location.state]);
    function loadExact(id: number) {
        helpDocsService.getOne(id).then((r) => {
            console.info(r);
            if (r) {
                setSearching(false);
                setSearched(true);
                setSearchResults([r.data]);
            }
        });
    }

    const handleKeyDown = (event: { key: string }) => {
        if (event.key === "Enter") {
            search(searchTerm);
        }
    };

    return (
        <div>
            <div>
                {!helpDocsQuery.isSuccess ? (
                    <Loader2 />
                ) : (
                    <>
                        <PageDescription
                            title="Sections"
                            description="Package Sections"
                            buttons={[
                                {
                                    label: "Add Section",
                                    onClick: () => {
                                        navigate("/faq/new");
                                    },
                                },
                            ]}
                        />
                        <TableNeogen
                            entries={
                                helpDocsQuery.data?.map((e) => {
                                    return {
                                        id: e.id,
                                        title: e.title,
                                        tags: e.tags,
                                        updated: e.updated,
                                        created: e.created,
                                        // ...e,
                                    };
                                }) ?? []
                            }
                            formatters={[
                                { field: "updated", type: "DateTime" },
                                { field: "created", type: "DateTime" },
                            ]}
                            actions={[
                                {
                                    label: "Edit",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);
                                            setShowAddFaq(true);
                                            navigate("/faq/edit/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    label: "Delete",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);

                                            setShowDeleteFaq(true);
                                        }
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <DeleteFaq show={showDeleteFaq} hide={() => setShowDeleteFaq(false)} existingEntry={existing} />
        </div>
    );
}
